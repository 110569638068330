import React from "react";
import { Switch, Route } from "react-router-dom";
import Home from "./Home";
import PrivacyPolicy from "./PrivacyPolicy";
import DownloadRedirect from "./DownloadRedirect";

const Main = () => {
  return (
    <Switch>
      {" "}
      {/* The Switch decides which component to show based on the current URL.*/}
      <Route exact path="/" component={Home}></Route>
      <Route exact path="/privacyPolicy" component={PrivacyPolicy}></Route>
      <Route exact path="/download" component={DownloadRedirect}></Route>
    </Switch>
  );
};

export default Main;
