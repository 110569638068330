import React from 'react';
import './App.css';
import Header from './header'
import Body from './Body'
import Subscribe from './subscribe';
import Footer from './footer';
import { Helmet } from 'react-helmet';
import ReactPixel from 'react-facebook-pixel';

const title = 'Tracker';

function Home() {
  ReactPixel.pageView()

  return (
    <div className="home">
      <style>{'body { background-color: #000000; }'}</style>
      <Helmet>
          <title>{ title }</title>
        </Helmet>
      <Header/>
      <Body/>
      <Subscribe/>
      <Footer/>
     
    </div>
  );
}

export default Home;