import React from "react";
import CookieConsent from "react-cookie-consent";
import { useMediaQuery } from "react-responsive";
import ReactPixel from 'react-facebook-pixel';
import {initGoogleTag} from '../util/analytics';

function ConsentBody(props) {
    return <div style={{
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '20px',
        letterSpacing: '0.25px'
    }}>
       <span>We and our partners are using technologies like Cookies and processing personal data like IP-address or browser information in order to personalize the information you see. 
This helps us to measure results or align our website content. Because we value your privacy, we are herewith asking your permission to use the following technologies.</span>
    </div>
}

function TrackerConsentMessage(props) {
    const phone = useMediaQuery({ query: "(max-device-width: 425px)" });

    const onAccept = () => {
        ReactPixel.grantConsent()
        initGoogleTag()
    }

    const style = {
        width: phone ? '100%' : '433px',
        height: '236px',
        left: phone ? '0px' : '48px',
        background: '#FFFFFF',
        color: 'black',
        'boxShadow': "0px 24px 38px rgba(0, 0, 0, 0.14), 0px 9px 46px rgba(0, 0, 0, 0.12), 0px 11px 15px rgba(0, 0, 0, 0.2)",
        'borderRadius': "16px"
    }

    const buttonStyle = {
        padding: '16px',
        borderRadius: '8px',
        display: 'inline-block',
        color: 'white',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontSize: '14px',
        fontWeight: '500',
        lineHeight: '16px',
        letterSpacing: '1.25px',
        textTransform: 'capitalize',
        width: '100px'
    }

    return <CookieConsent
        location="bottom"
        buttonText="Accept"
        cookieName="consent"
        style={style}
        onAccept={onAccept}
        buttonWrapperClasses={'consentButton'}
        buttonStyle={{ 
            ...buttonStyle,
            background: '#503BFF',
            color: 'white',
        }}
        enableDeclineButton={true}
        declineButtonText={'Decline'}
        declineButtonStyle={{
            ...buttonStyle,
            background: 'white',
            color: '#503BFF',
            borderColor: '#503BFF',
            border: '1px rgb(80, 59, 255) important!',
            borderWidth: 'thin',
        }}
        expires={150}
        {...props}
    >
        <ConsentBody></ConsentBody>
  </CookieConsent>
}


export default TrackerConsentMessage;