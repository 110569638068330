import React from "react";
import logo from "./Assets/5e92fedc333832466f3dd829_Logo.svg";
import smallLogo from "./Assets/5e92fedc3338321d193dd82a_Icon (2).svg";
import { useMediaQuery } from "react-responsive";
import { getMobileOperatingSystem, redirect } from "./util/downloadRedirect";

function Header() {
  const isHugeScreen = useMediaQuery({ query: "(max-device-width: 2560px)" });
  const isDesktopOrLaptop = useMediaQuery({
    query: "(max-device-width: 1440px)",
  });
  const largeTablet = useMediaQuery({ query: "(max-device-width: 768px)" });
  const isPhoneOrTablet = useMediaQuery({
    query: "(max-device-width: 500px)",
  });
  const phone = useMediaQuery({ query: "(max-device-width: 340px)" });

  const download = {
    marginLeft: "15px",
    scrollBehaviour: "smooth",
  };
  const logoForMobile = {
    display: "none",
  };
  const features = {
    display: "unset",
    scrollBehavior: "smooth",
  };

  const appLogoStyle = {
    width: "20%",
  };
  const headerLinks = {
    float: "right",
  };

  const headerStyle = {
    width: "70%",
    margin: "0 auto",
    height: "60px",
    paddingBottom: "20px",
    paddingTop: "20px",
  };

  if (isDesktopOrLaptop) {
    appLogoStyle.width = "20%";
    headerLinks.float = "right";
    appLogoStyle.paddingBottom = "2%";
    headerStyle.marginBottom = "1%";
  }
  if (isHugeScreen) {
    appLogoStyle.width = "fit-content";
  }
  if (largeTablet) {
    headerStyle.width = "70%";
    appLogoStyle.width = "25%";
    headerStyle.marginBottom = "2%";
  }

  if (isPhoneOrTablet) {
    logoForMobile.display = "block";
    logoForMobile.width = "15%";
    logoForMobile.marginLeft = "5%";
    logoForMobile.marginTop = "2%";
    appLogoStyle.display = "none";
    headerLinks.display = "inline-block";
    headerLinks.marginTop = "-10%";
    headerLinks.float = "right";
    features.display = "none";
    headerLinks.marginRight = "5%";
    headerStyle.width = "100%";
  }
  if (phone) {
    logoForMobile.display = "block";
    logoForMobile.width = "15%";
    logoForMobile.marginLeft = "5%";
    logoForMobile.marginTop = "2%";
    appLogoStyle.display = "none";
    headerLinks.display = "inline-block";
    headerLinks.marginTop = "-10%";
    headerLinks.float = "right";
    features.display = "none";
    headerLinks.marginRight = "5%";
    headerStyle.width = "100%";
  }

  function redirectToDownload() {
    const system = getMobileOperatingSystem();

    if (system === "unknown") {
      pageScroll();
    } else {
      redirect();
    }
  }

  function pageScroll() {
    const prePosition = window.scrollY;
    window.scrollBy(0, 50);
    const postPosition = window.scrollY;
    if (prePosition !== postPosition) {
      setTimeout(pageScroll, 20);
    }
    console.log("test");
  }

  function getButton() {
    return (
      <button id="download" style={download} onClick={redirectToDownload}>
        Download
      </button>
    );
  }

  return (
    <div style={headerStyle}>
      <a href=".">
        {" "}
        <img src={logo} className="App-logo" alt="logo" style={appLogoStyle} />
      </a>
      <a href=".">
        <img
          src={smallLogo}
          className="smallLogo"
          alt="logo"
          style={logoForMobile}
        />
      </a>
      <p id="headerLinks" style={headerLinks}>
        {getButton()}
      </p>
    </div>
  );
}

export default Header;
