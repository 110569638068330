import React from "react";
import "./App.css";
import Header from "./header";
import Footer from "./footer";
import PrivacyPolicy from "./policyBody";
import { Helmet } from "react-helmet";

const privacyTitle = "Privacy Policy";

function Policy() {
  return (
    <div className="Policy">
      <style>{"body { background-color: #000000; }"}</style>
      <Helmet>
        <title>{privacyTitle}</title>
      </Helmet>
      <Header />
      <PrivacyPolicy />
      <Footer />
    </div>
  );
}

export default Policy;
