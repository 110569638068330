import React from "react";
import { useMediaQuery } from "react-responsive";

function PrivacyPolicy() {
  const largeTablet = useMediaQuery({ query: "(max-device-width: 768px)" });
  const isPhoneOrTablet = useMediaQuery({ query: "(max-device-width: 500px)" });
  const phone = useMediaQuery({ query: "(max-device-width: 340px)" });

  const privacyContainer = {
    width: "60%",
    backgroundColor: "white",
  };
  const containerText = {
    width: "75%",
    margin: "0 auto",
  };

  const paragrapghs = {
    fontFamily: "Roboto, sans-serif",
    color: "#000",
    fontSize: "16px",
    lineHeight: "24px",
  };

  const list = {
    paddingBottom: "10px",
    lineHeight: "30px",
  };

  const unorderedLists = {
    paddingBottom: "20px",
  };

  if (largeTablet || isPhoneOrTablet || phone) {
    containerText.width = "95%";
    privacyContainer.width = "85%";
  }

  return (
    <div>
      <div className="containerBody" style={privacyContainer}>
        <div className="containerText" style={containerText}>
          <h2
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              fontFamily: "Roboto, sans-serif",
              color: "#000",
              fontSize: "40px",
              lineHeight: "48px",
              fontWeight: "700",
            }}
          >
            <strong>Phenon Privacy Policy</strong>
          </h2>
          <p style={paragrapghs}>Last Updated: May 3, 2020</p>
          <p></p>
          <p style={paragrapghs}>
            Privacy is of utmost importance at the Phenon Tech OU (“Phenon”). We
            recognize the significance of protecting information which is stored
            on our servers or network or is intended to be stored on our servers
            or network and which relates to an individual. The data we protect
            are the “Personal Data” which is any information relating to an
            identified or identifiable natural person, sometimes called a data
            subject, and have made protecting the privacy and the
            confidentiality of Personal Data a fundamental component of the way
            we do business. This Privacy Policy informs you of the ways we work
            to ensure privacy and the confidentiality of Personal Data and
            describes the information we gather, how we use those Personal Data
            and the circumstances under which we disclose such information to
            third-parties.
          </p>
          <p style={paragrapghs}>
            This Privacy Policy is designed to address regulatory requirements
            of the jurisdictions in which Phenon offers its Services, including
            the General Data Protection Regulation (“GDPR”), as enacted by the
            European Commission. If you do not agree with this Privacy Policy,
            in general, or any part of it, you should not use the Services. This
            Privacy Policy is periodically reviewed to ensure that any new
            obligations and changes to our business model are taken into
            consideration. We may amend this Privacy Policy at any time by
            posting an amended version on our website.
          </p>
          <p>
            <br></br>
          </p>

          <p style={paragrapghs}>Contents:</p>
          <ol style={list}>
            <li>Information About Phenon</li>
            <li>Collection of Personal Data</li>
            <li>Use of Personal Data</li>
            <li>Disclosure of Personal Data</li>
            <li>Security of Personal Data</li>
            <li>Retention of Personal Data</li>
            <li>Storage of Personal Data</li>
            <li>Your Rights</li>
            <li>Acceptance</li>
            <li>Questions and Complaints</li>
          </ol>
          <h4>1. Information About Phenon</h4>
          <p style={paragrapghs}>
            Our Services are offered through one or more subsidiaries of Phenon
            Tech OU. By using the Services, you understand that your Personal
            Data may be used by one of the following entities (each, a
            “Controller”):
          </p>
          <ul>
            <li>Phenon Tech OU</li>
          </ul>
          <p style={paragrapghs}>
            You may contact our Data Protection Officer (“DPO”) by email at
            <a href="mailto:privacy@phenon.io"> privacy@phenon.io</a>
          </p>
          <p></p>
          <h4>2. Collection of Personal Data</h4>
          <p style={paragrapghs}>
            When you access or use the Services, we collect the following
            information:
          </p>
          <p style={paragrapghs}>
            Information you may provide to us: You may give us information about
            you by filling in forms on our website or through our app or by
            corresponding with us by phone, email or otherwise. This includes
            information you provide when you register to use the Services and
            when you report a problem with the website or with our app.
          </p>
          <p style={paragrapghs}>
            Information we collect about you: With regard to each of your visits
            to our website or our app we automatically collect the following
            information:
          </p>
          <ul>
            <li style={unorderedLists}>
              Login Information: We log technical information about your use of
              the Services, including the type of browser and version you use,
              the portfolio identifier, access time the Internet Protocol (IP)
              address used to create the portfolio and the most recent IP
              address used to access the portfolio.
            </li>
            <li style={unorderedLists}>
              Device Information: We collect information about the device you
              use to access your account, including the hardware model,
              operating system and version, and unique device identifiers, but
              this information is anonymized and not tied to any particular
              person.
            </li>
            <li style={unorderedLists}>
              User-provided Information: When you use the Service, you may
              provide and we may collect your information related to using our
              services. Examples of this information include portfolio assets,
              their amounts, time of purchase and other.
            </li>
          </ul>
          <p style={paragrapghs}>
            Information We Collect from Other Sources: We also receive
            information from other sources and combine that with the information
            we collect through our Services. For instance:
          </p>
          <ul>
            <li style={unorderedLists}>
              We use third-party services that may be co-branded as Phenon but
              will do so with clear notice. Any third-party services may collect
              information as determined by their own privacy policies.
            </li>
            <li style={unorderedLists}>
              Advertising or analytics providers may provide us with anonymized
              information about you, including but not limited to, how you found
              our website.
            </li>
          </ul>
          <h4>3. Use of Personal Data</h4>
          <p style={paragrapghs}>We will use your information to:</p>
          <ul>
            <li style={unorderedLists}>
              Understand and strive to meet your needs and preferences in using
              our Services;
            </li>
            <li style={unorderedLists}>
              Develop new and enhance existing service and product offerings;
            </li>
            <li style={unorderedLists}>
              Manage and develop our business and operations;
            </li>
            <li style={unorderedLists}>
              Carry out any actions for which we have received your consent;
            </li>
            <li style={unorderedLists}>
              Prevent and investigate fraudulent or other criminal activity;
            </li>
            <li style={unorderedLists}>
              To address service requests and resolve user questions;
            </li>
            <li style={unorderedLists}>
              Meet legal and regulatory requirements.
            </li>
          </ul>
          <p style={paragrapghs}>
            We also reserve the right to use aggregated Personal Data to
            understand how our users use our Services, provided that those data
            cannot identify any individual.
          </p>
          <p style={paragrapghs}>
            We also use third-party web analytics tools that help us understand
            how users engage with our website. These third-parties may use
            first-party cookies to track user interactions to collect
            information about how users use our website. This information is
            used to compile reports and to help us improve our website. The
            reports disclose website trends without identifying individual
            visitors. You can opt-out of such third-party analytic tools without
            affecting how you visit our site. For more information on
            opting-out, please contact{" "}
            <a href="mailto:privacy@phenon.io">privacy@phenon.io</a>.{" "}
          </p>
          <p style={paragrapghs}>
            We will process your Personal Data legally and fairly and not use it
            outside the purposes of which we have informed you, including
            selling it individually or in the aggregate for commercial use.
          </p>
          <p></p>
          <h4>4. Disclosure of Personal Data</h4>
          <p style={paragrapghs}>
            We may share your information with selected recipients to perform
            functions required to provide certain Services to you. All such
            third parties will be contractually bound to protect data in
            compliance with our Privacy Policy. The categories of recipients
            include:
          </p>
          <ul>
            <li style={unorderedLists}>
              Companies within the Phenon corporate family located in the United
              States and the United Kingdom, in order to provide the Services to
              you.
            </li>
            <li style={unorderedLists}>
              Cloud service providers to store certain personal data and for
              disaster recovery services, as well as, for the performance of any
              contract we enter into with you.
            </li>
          </ul>

          <p style={paragrapghs}>
            We also may share Personal Data with a buyer or other successor in
            the event of a merger, divestiture, restructuring, reorganization,
            dissolution or other sale or transfer of some or all of Phenon’s
            assets, whether as a going concern or as part of bankruptcy,
            liquidation or similar proceeding, in which Personal Data held by
            Phenon is among the assets transferred.
          </p>
          <p style={paragrapghs}>
            Except where we are required by law to disclose Personal Data, or
            are exempted from, released from or not subject to any legal
            requirement concerning the disclosure of Personal Data, we will
            require any person to whom we provide your Personal Data to agree to
            comply with our Privacy Policy in force at that time or requirements
            substantially similar to such policy. We will make reasonable
            commercial efforts to ensure that they comply with such policy or
            requirements, however, where not expressly prohibited by law, we
            will have no liability to you, if any person fails to do so.
          </p>
          <p style={paragrapghs}>
            We shall require any third-party, including without limitation, any
            government or enforcement entity, seeking access to the data we hold
            to a court order, or equivalent proof that they are statutorily
            authorized to access your data and that their request is valid and
            within their statutory or regulatory power.
          </p>
          <p></p>
          <h4>5. Security of Your Personal Data</h4>
          <p style={paragrapghs}>
            We protect Personal Data with appropriate physical, technological
            and organizational safeguards and security measures. Your Personal
            Data comes to us via the internet which chooses its own routes and
            means, whereby information is conveyed from location to location. We
            audit our procedures and security measures regularly to ensure they
            are being properly administered and remain effective and
            appropriate. Every member of Phenon is committed to our privacy
            policies and procedures to safeguard Personal Data. Our site has
            security measures in place to protect against the loss, misuse and
            unauthorized alteration of the information under our control. More
            specifically, our server uses TLS (Transport Layer Security)
            security protection by encrypting your Personal Data to prevent
            individuals from accessing such Personal Data as it travels over the
            internet.
          </p>
          <p></p>
          <h4>6. Retention of Your Personal Data</h4>
          <p style={paragrapghs}>
            The length of time we retain Personal Data outside our back-up
            system varies depending on the purpose for which it was collected
            and used, as follows:
          </p>
          <ul>
            <li style={unorderedLists}>
              Country location data: while user remains active, stored in the
              Estonia.
            </li>
            <li style={unorderedLists}>
              Data on your preferences: while user remains active, stored in the
              Estonia.
            </li>
            <li style={unorderedLists}>
              IP address login: until subsequent login from a new IP, stored in
              the Estonia.
            </li>
          </ul>
          <p style={paragrapghs}>
            When Personal Data is no longer necessary for the purpose for which
            it was collected, we will remove any details that identifies you or
            we will securely destroy the records, where permissible.{" "}
          </p>
          <p style={paragrapghs}>
            Except where prohibited by law, this period may extend beyond the
            end of the particular relationship with us, but only for as long as
            we are bound to do so for the audit, regulatory or other accounting
            purposes. When Personal Data is no longer needed, we have procedures
            either to destroy, delete, erase or convert it to an anonymous form.
            If you have opted-out of receiving marketing communications, we will
            hold your details on our suppression list so that we know you do not
            want to receive these communications.
          </p>
          <p style={paragrapghs}>
            After you have terminated the use of our Services, we reserve the
            right to maintain your Personal Data as part of our standard back-up
            procedures in an aggregated format.
          </p>
          <p></p>
          <h4>7. Storage of Personal Data</h4>
          <p style={paragrapghs}>
            Phenon stores your Personal Data at secure locations in the EU.
            Phenon ensures that appropriate security standards are in place
            regarding the safeguarding, confidentiality, and security of Data.
          </p>
          <p style={paragrapghs}>
            The information that we collect from you will be transferred to, and
            stored in, destinations outside of your country and the European
            Economic Area ("EEA") as described below:
          </p>
          <p style={paragrapghs}>
            We may transfer your Personal Data outside the EEA and the UK to
            other company subsidiaries, service providers and business partners
            (i.e., Data Processors) who are engaged on our behalf. To the extent
            that we transfer your Personal Data outside of the EEA and UK, we
            will ensure that the transfer is lawful and that Data Processors in
            third countries are obliged to comply with the GDPR and the{" "}
            <a href="http://www.legislation.gov.uk/ukpga/2018/12/contents/enacted">
              UK Data Protection Act 2018
            </a>
            . If transfers of Personal Data are processed in the US, we may in
            some cases rely on applicable standard contractual clauses.
          </p>
          <p></p>
          <h4>8. Your Rights</h4>
          <p>
            The rights that are available to you in relation to the Personal
            Data we hold about you are outlined below.
          </p>
          <p></p>
          <h5>Information Access</h5>
          <p style={paragrapghs}>
            If you ask us, we will confirm whether we are processing your
            Personal Data and, if so, what information we process and, if
            requested, provide you with a copy of that information within 30
            days from the date of your request.
          </p>
          <p></p>
          <h5>Rectification</h5>
          <p style={paragrapghs}>
            It is important to us that your Personal Data is up-to-date. We will
            take all reasonable steps to make sure that your Personal Data
            remains accurate, complete and up-to-date. If the Personal Data we
            hold about you is inaccurate or incomplete, you are entitled to have
            it rectified. If we have disclosed your Personal Data to others, we
            will let them know about the rectification where possible. If you
            ask us, if possible and lawful to do so, we will also inform you
            with whom we have shared your Personal Data so that you can contact
            them directly.
          </p>
          <p style={paragrapghs}>
            You may inform us at any time that your personal details have
            changed by emailing us at{" "}
            <a href="mailto:privacy@phenon.io">privacy@phenon.io</a>
            and we will change your Personal Data in accordance with your
            instructions. To proceed with such requests, in some cases we may
            need supporting documents from you as proof that we are required to
            keep for regulatory or other legal purposes.
          </p>
          <p></p>
          <h5>Erasure</h5>
          <p style={paragrapghs}>
            You can ask us to delete or remove your Personal Data in certain
            circumstances such as if we no longer need it, provided that we have
            no legal or regulatory obligation to retain that data. Such requests
            will be subject to any agreements that you have entered into with
            us, and to any retention limits, we are required to comply with in
            accordance with applicable laws and regulations. If we have
            disclosed your Personal Data to others, we will let them know about
            the erasure request where possible. If you ask us, if possible and
            lawful to do so, we will also inform you with whom we have shared
            your Personal Data so that you can contact them directly.
          </p>
          <p></p>
          <h5>Processing restrictions</h5>
          <p style={paragrapghs}>
            You can ask us to block or suppress the processing of your Personal
            Data in certain circumstances, such as, if you contest the accuracy
            of that Personal Data or object to us processing it. It will not
            stop us from storing your Personal Data. We will inform you before
            we decide not to agree with any requested restriction. If we have
            disclosed your Personal Data to others, we will let them know about
            the restriction of processing where possible. If you ask us, if
            possible and lawful to do so, we will also inform you with whom we
            have shared your Personal Data so that you can contact them
            directly.
          </p>
          <p></p>
          <h5>Data portability</h5>
          <p style={paragrapghs}>
            In certain circumstances, you might have the right to obtain
            Personal Data you have provided us with (in a structured, commonly
            used and machine-readable format) and to re-use it elsewhere or ask
            us to transfer this to a third party of your choice.
          </p>
          <p></p>
          <h5>Objection</h5>
          <p style={paragrapghs}>
            You can ask us to stop processing your Personal Data, and we will do
            so if we are:
          </p>
          <ul>
            <li style={unorderedLists}>
              Relying on our own or someone else’s legitimate interests to
              process your Personal Data, except if we can demonstrate
              compelling legal grounds for the processing;
            </li>
            <li style={unorderedLists}>
              Processing your Personal Data for direct marketing; or
            </li>
            <li style={unorderedLists}>
              Processing your Personal Data for research, unless we reasonably
              believe such processing is necessary or prudent for the
              performance of a task carried out in the public interest (such as
              by a regulatory or enforcement agency).
            </li>
          </ul>
          <h5>Automated decision-making and profiling</h5>
          <p style={paragrapghs}>
            If we make a decision about you based solely on an automated process
            (e.g. through automatic profiling) that affects your ability to
            access our Services or has another significant effect on you, you
            can request not to be subject to such a decision unless we can
            demonstrate to you that such a decision is necessary for entering
            into, or the performance of, a contract between us. Even if a
            decision is necessary for entering into or performing a contract,
            you may contest the decision and require human intervention. We may
            not be able to offer our Services if we agree to such a request by
            terminating our relationship with you. You can exercise any of these
            rights by contacting us at{" "}
            <a href="mailto:privacy@phenon.io">privacy@phenon.io</a>.
          </p>
          <p></p>
          <h4>9. Acceptance</h4>
          <p style={paragrapghs}>
            By using the Services, you signify your agreement to this Privacy
            Policy. Phenon reserves the right to change or amend this Privacy
            Policy at any time. If we make any material changes to this Privacy
            Policy, the revised Policy will be posted here and we will notify
            our users at least 30 days prior to the changes taking effect so
            that you are always aware of what information we collect, how we use
            it and under what circumstances we disclose it. Please check this
            page frequently to see any updates or changes to this Privacy
            Policy.
          </p>
          <p></p>
          <h4>10. Questions and Complaints</h4>
          <p style={paragrapghs}>
            Any questions about this Privacy Policy, the collection, use and
            disclosure of Personal Data by Phenon or access to your Personal
            Data as required by law (to be disclosed should be directed to{" "}
            <a href="mailto:privacy@phenon.io">privacy@phenon.io</a>.
          </p>
          <p style={paragrapghs}>
            In the event that you wish to make a complaint about how we process
            your Personal Data, please contact us in the first instance at In
            the event that you wish to make a complaint about how we process
            your personal data, please contact us in the first instance at{" "}
            <a href="mailto:privacy@phenon.io">privacy@phenon.io</a> and we will
            attempt to handle your request as soon as possible. This is without
            prejudice to your right to launch a claim with the data protection
            supervisory authority in the country in which you live or work where
            you think we have violated data protection laws.
          </p>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
