import ReactPixel from 'react-facebook-pixel';
import TagManager from 'react-gtm-module'
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const gtmId = 'GTM-T5FSZJN';
const fbPixelId = '507382853996177';

function consentExists() {
    return cookies.get('consent') === 'true'
}

function initGoogleTag() {
    TagManager.initialize({gtmId})
}

function init() {
    const options = {
        autoConfig: true, 
        debug: false,
    };

    ReactPixel.init(fbPixelId, {}, options);

  
    if (consentExists()) {
        TagManager.initialize({gtmId})
    }

    if (!consentExists()) {
        ReactPixel.revokeConsent();
    }
}

export {init, initGoogleTag};