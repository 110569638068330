import React, { useEffect } from "react";
import "./App.css";
import { redirect } from "./util/downloadRedirect";

function DownloadRedirect() {
  useEffect(() => {
    redirect();
  });

  return <div></div>;
}

export default DownloadRedirect;
