import React from "react";
import facebook from "./Assets/5eadc67c00e6c6bc41ea0c70_Facebook 2.svg";
import twitter from "./Assets/5eadc61d6af9c82318db1cc7_Twitter 2.svg";
import telegram from "./Assets/5eadc61ecd11463a67744b0f_Telegram 2.svg";
import smallLogo from "./Assets/5e92fedc3338321d193dd82a_Icon (2).svg";
import { Link } from "react-router-dom";

import { useMediaQuery } from "react-responsive";

function Footer() {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(max-device-width: 1440px)",
  });
  const isLaptopOrTablet = useMediaQuery({
    query: "(max-device-width: 1024px)",
  });
  const largeTablet = useMediaQuery({ query: "(max-device-width: 768px)" });
  const isPhoneOrTablet = useMediaQuery({
    query: "(max-device-width: 500px)",
  });
  const phone = useMediaQuery({ query: "(max-device-width: 340px)" });

  const socialMediaLinks = {
    display: "grid",
    backgroundRepeat: "no-repeat",
  };
  const facebookStyle = {
    width: "30px",
  };

  const telegramStyle = {
    width: "30px",
    marginLeft: "20px",
  };

  const twitterStyle = {
    width: "30px",
    marginLeft: "20px",
  };

  const privacyPolicy = {
    color: "white",
  };

  const footerList = {
    display: "grid",
    justifyContent: "center",
    gap: "20px",
    listStyleType: "none",
    justifyItems: "center",
    padding: "0px",
  };

  const listItem = {
    display: "inline-block",
    marginTop: "10%",
    marginBottom: "10%",
  };
  if (isLaptopOrTablet) {
    // socialMediaLinks.marginLeft = '38%'
  }
  if (isDesktopOrLaptop) {
    socialMediaLinks.justifyContent = "center";
    listItem.float = "right";
    footerList.display = "block";
    listItem.marginRight = "16%";
    listItem.marginTop = "0px";
    listItem.marginBottom = "0px";
  }

  if (largeTablet) {
    socialMediaLinks.display = "grid";
    footerList.display = "grid";
    footerList.justifyContent = "center";
    footerList.gap = "20px";
    footerList.listStyleType = "none";
    footerList.justifyItems = "center";
    listItem.marginRight = "0px";
  }
  if (isPhoneOrTablet) {
    socialMediaLinks.display = "grid";
    footerList.display = "grid";
    footerList.justifyContent = "center";
    footerList.gap = "20px";
    footerList.listStyleType = "none";
    footerList.justifyItems = "center";
    listItem.marginRight = "0px";
  }

  if (phone) {
    socialMediaLinks.display = "grid";
    footerList.display = "grid";
    footerList.justifyContent = "center";
    footerList.gap = "20px";
    footerList.listStyleType = "none";
    footerList.justifyItems = "center";
    listItem.marginRight = "0px";
  }

  return (
    <div className="footer">
      <ul style={footerList}>
        <div>
          <li style={{ display: "inline-block" }}>
            {" "}
            <img
              src={smallLogo}
              className="smallLogo"
              alt="logo"
              style={{ display: "none" }}
            />
          </li>
        </div>
        <div className="socialMediaLinks" style={socialMediaLinks}>
          <li style={{ display: "inline-block" }}>
            {" "}
            <a
              href="https://www.facebook.com/trackerbyphenon"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={facebook}
                className="facebook"
                alt="facebook"
                style={facebookStyle}
              />
            </a>
            <a
              href="https://twitter.com/TrackerByPhenon"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none" }}
            >
              {" "}
              <img
                src={twitter}
                className="twitter"
                alt="twitter"
                style={twitterStyle}
              />
            </a>
            <a
              href="https://t.me/joinchat/AAAAAETk8BBzF75wtZ_jbQ"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none" }}
            >
              {" "}
              <img
                src={telegram}
                className="telegram"
                alt="telegram"
                style={telegramStyle}
              />
            </a>
          </li>
        </div>

        <div>
          <li style={listItem}>
            {" "}
            <Link
              to="/privacyPolicy"
              target="_blank"
              id="privacyPolicy"
              style={privacyPolicy}
            >
              Privacy Policy
            </Link>
          </li>
        </div>
      </ul>
      <p id="footerText">Copyright © 2020 Phenon. All rights reserved.</p>
    </div>
  );
}

export default Footer;
