import React from 'react';
import './App.css';
import Main from './Main';
import AnalyticsConsent from './CookieConsent/CookieConsent';
import {init} from './util/analytics';

function App() {
  init();
  
  return (
    <div className="App">
      <style>{'body { background-color: #000000; }'}</style>

      <AnalyticsConsent></AnalyticsConsent>
      <Main />

    </div>
  );
}

export default App;