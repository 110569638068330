import React from "react";
import googlePlay from "./Assets/5e9c636db27cf63cad9dabff_Google play badge.png";
import appStore from "./Assets/5e9c636d9ba599ed1b02e664_Play store badge (1).png";
// import picture from "./Assets/5eaeed015c6b736a5c1ffadb_hiro.jpg";
import picture from "./Assets/hiro_img.png";

import { useMediaQuery } from "react-responsive";

function Body() {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-device-width: 1440px)",
  });
  const isLaptopOrTablet = useMediaQuery({
    query: "(min-device-width: 1024px)",
  });
  const largeTablet = useMediaQuery({ query: "(max-device-width: 769px)" });
  const isPhoneOrTablet = useMediaQuery({
    query: "(max-device-width: 500px)",
  });
  const phone = useMediaQuery({ query: "(max-device-width: 340px)" });

  const container = {
    width: "60%",
  };
  const firstRow = {
    display: "flex",
  };
  const firstParagraphHeading = {
    display: "inline-block",
  };

  const mobilePhonePic = {
    width: "100%",
    display: "inline-block",
    marginTop: "2%",
  };

  const addCoins = {
    textAlign: "center",
    color: "#000",
    fontSize: "4vh",
    lineHeight: "6vh",
    fontFamily: "Roboto, sans-serif",
    marginTop: "20%",
    fontWeight: "400",
  };

  const secondPText = {
    textAlign: "left",
    color: "#000",
    fontSize: "4vh",
    lineHeight: "6vh",
    fontFamily: "Roboto, sans-serif",
    marginTop: "20%",
    fontWeight: "400",
  };

  const firstVideo = {
    width: "80%",
    marginTop: "50px",
    display: "inline-block",
  };

  const secondVideo = {
    float: "right",
    width: "80%",
  };

  const shownOnMobile = {
    display: "none",
  };

  const shownOnDesktop = {
    display: "block",
  };

  if (isLaptopOrTablet || isDesktopOrLaptop) {
    mobilePhonePic.maxWidth = "700px";
    mobilePhonePic.display = "inline-block";
    mobilePhonePic.marginLeft = "4%";
    firstParagraphHeading.display = "inline-block";
    firstRow.display = "flex";
    addCoins.verticalAlign = "left";
    addCoins.width = "50%";
    addCoins.display = "inline-block";
    addCoins.float = "right";
    addCoins.marginTop = "25%";
    addCoins.textAlign = "left";
    firstVideo.width = "40%";
    secondPText.verticalAlign = "top";
    secondPText.width = "60%";
    secondPText.display = "inline-block";
    secondPText.float = "left";
    secondPText.marginTop = "25%";
    secondVideo.width = "40%";
    firstParagraphHeading.width = "50%";
  }

  if (largeTablet) {
    shownOnMobile.display = "block";
    shownOnDesktop.display = "none";
    mobilePhonePic.display = "block";
    firstParagraphHeading.display = "block";
    firstRow.display = "initial";
    mobilePhonePic.marginTop = "10%";
    mobilePhonePic.marginBottom = "10%";
    addCoins.textAlign = "center";
    addCoins.maxWidth = "500px";
    addCoins.display = "block";
    addCoins.margin = "0 auto";
    addCoins.backgroundRepeat = "no-repeat";
    addCoins.marginTop = "20%";
    firstVideo.width = "100%";
    secondPText.textAlign = "center";
    secondPText.maxWidth = "500px";
    secondPText.display = "block";
    secondPText.margin = "0 auto";
    secondPText.marginTop = "20%";
    secondVideo.width = "100%";
  }

  if (isPhoneOrTablet || phone) {
    mobilePhonePic.display = "block";
    firstParagraphHeading.display = "block";
    firstRow.display = "initial";
    container.backgroundRepeat = "no-repeat";
    container.marginTop = "2%";
    mobilePhonePic.marginTop = "10%";
    mobilePhonePic.marginBottom = "10%";
    addCoins.textAlign = "center";
    addCoins.maxWidth = "500px";
    addCoins.display = "block";
    addCoins.margin = "0 auto";
    addCoins.backgroundRepeat = "no-repeat";
    firstVideo.width = "100%";
    secondPText.textAlign = "center";
    secondPText.maxWidth = "500px";
    secondPText.display = "block";
    secondPText.margin = "0 auto";
    secondPText.marginTop = "20%";
    container.width = "80%";
    secondVideo.width = "100%";
    secondVideo.float = "initial";
  }
  if (phone) {
    container.width = "90%";
  }

  return (
    <div>
      <div className='containerBody' style={container}>
        <div className='firstRow' style={firstRow}>
          <div
            className='col-sm-12 col-md-5 col-lg-5'
            id='firstWidget'
            style={firstParagraphHeading}
          >
            <h1 id="heading">
              Probably the simplest FREE investing portfolio tracker
            </h1>

            <div className='col-sm-12 col-md-6 col-lg-6' style={{...mobilePhonePic, ...shownOnMobile}}>
              <img src={picture} className='picture' alt='home' style={mobilePhonePic} />
            </div>

            <div style={shownOnMobile}>
              <a
                href='https://apps.apple.com/us/app/tracker-by-phenon/id1497464162?ls=1'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img src={appStore} className='appStore' alt='app-store' />
              </a>
              <a
                href='https://play.google.com/store/apps/details?id=io.phenon.tracker'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img src={googlePlay} className='googlePlay' alt='google-play' />
              </a>
            </div>

            <p id="firstParagpraph">
              <h4>Features:</h4>- Add your stocks and digital holdings <br></br>-
              Track the value and profit of your portfolio using global average
              prices <br></br>- Use any available currency or asset as portfolio
              base currency
              <br></br>
              <h4>Application supports</h4>- US Market Stocks, ETF's & Index
              funds <br></br>- TOP 500 digital assets (BTC, ETH, LTC, XRP,
              XMR, EOS, ZEC, etc) <br></br>- 30+ fiat currencies (EUR, USD, GBP,
              JPY, CHF, etc). <br></br>
              <br></br>No ads. Enjoy your ad-free experience!
            </p>

            <div style={shownOnDesktop}>
              <a
                href='https://apps.apple.com/us/app/tracker-by-phenon/id1497464162?ls=1'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img src={appStore} className='appStore' alt='app-store' />
              </a>
              <a
                href='https://play.google.com/store/apps/details?id=io.phenon.tracker'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img src={googlePlay} className='googlePlay' alt='google-play' />
              </a>
            </div>
          </div>

          <div className='col-sm-12 col-md-6 col-lg-6' style={{ ...mobilePhonePic, ...shownOnDesktop }}>
            <img src={picture} className='picture' alt='home' style={mobilePhonePic} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Body;
